import { Camera, type CameraPermissionState, CameraSource } from "@capacitor/camera";

import { CameraPermissionDeniedError } from "../errors";
import { type AllowedCameraSourceType } from "../types";

const ACCEPTABLE_PERMISSION_STATUSES = new Set(["granted", "limited"]);

async function getCameraPermission(
  cameraSource: AllowedCameraSourceType
): Promise<CameraPermissionState> {
  const { camera, photos } = await Camera.checkPermissions();
  return cameraSource === CameraSource.Camera ? camera : photos;
}

async function requestCameraPermission(
  cameraSource: AllowedCameraSourceType
): Promise<CameraPermissionState> {
  const requiredPermission = cameraSource === CameraSource.Camera ? "camera" : "photos";
  const { camera, photos } = await Camera.requestPermissions({
    permissions: [requiredPermission],
  });
  return cameraSource === CameraSource.Camera ? camera : photos;
}

export async function checkAndRequestPhotoPermissions(
  cameraSource: AllowedCameraSourceType
): Promise<void> {
  let permissionStatus = await getCameraPermission(cameraSource);

  if (permissionStatus === "denied") {
    throw new CameraPermissionDeniedError({
      message: `Camera permission denied for ${cameraSource}`,
      cameraSource,
    });
  }

  /* 
    The user can decline without blocking the prompt completely,
    which is reflected in the state 'prompt-with-rationale'
    This is why we receive the permission status twice and 
    have some logic duplication, to ensure our user is only prompted once
   */
  if (!ACCEPTABLE_PERMISSION_STATUSES.has(permissionStatus)) {
    permissionStatus = await requestCameraPermission(cameraSource);

    if (!ACCEPTABLE_PERMISSION_STATUSES.has(permissionStatus)) {
      throw new CameraPermissionDeniedError({
        message: `Camera permission denied for ${cameraSource}`,
        cameraSource,
      });
    }
  }
}

import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logError } from "@src/appV2/lib/analytics";
import {
  type AcceptableUploadImageContentTypes,
  isAcceptableImageUploadContentType,
} from "@src/appV2/lib/files";
import { getFittedDimensionsInInches, getImageDimensions } from "@src/appV2/lib/images";
import { jsPDF as JsPdf } from "jspdf";

interface ImageFile {
  dataUrlBase64: string;
  contentType: AcceptableUploadImageContentTypes;
  extension: string;
}

const LETTER_PAPER_WIDTH_IN_INCHES = 8.5;
const LETTER_PAPER_HEIGHT_IN_INCHES = 11;
const X_MARGIN = 0.5;
const Y_MARGIN = 0.5;

const MAX_ALLOWED_LETTER_PAPER_WIDTH = LETTER_PAPER_WIDTH_IN_INCHES - X_MARGIN;
const MAX_ALLOWED_LETTER_PAPER_HEIGHT = LETTER_PAPER_HEIGHT_IN_INCHES - Y_MARGIN;

export const constructPdfFromMultipleImages = async (imageFiles: ImageFile[]): Promise<Blob> => {
  if (imageFiles.length === 0) {
    throw new Error("Failed to create PDF. No images provided");
  }

  imageFiles.forEach((imageFile, index) => {
    if (!isAcceptableImageUploadContentType(imageFile.contentType)) {
      const imageName = `image #${(index + 1).toString()}`;
      throw new Error(
        `Failed to create PDF. One of the images (${imageName}) with content type: ${imageFile.contentType} is not an image.`
      );
    }
  });

  try {
    const documentPdfFile = new JsPdf({
      compress: true,
      format: "letter",
      unit: "in",
    });
    documentPdfFile.deletePage(1);

    const promises = imageFiles.map(async (imageFile) => {
      const { width, height } = await getImageDimensions(imageFile.dataUrlBase64);
      return { imageFile, width, height };
    });

    await Promise.all(promises).then((imageDetails) => {
      imageDetails.forEach(({ imageFile, width, height }) => {
        const { scaledWidth, scaledHeight } = getFittedDimensionsInInches({
          imageWidthInPixels: width,
          imageHeightInPixels: height,
          fittedWidthInInches: MAX_ALLOWED_LETTER_PAPER_WIDTH,
          fittedHeightInInches: MAX_ALLOWED_LETTER_PAPER_HEIGHT,
        });
        documentPdfFile
          .addPage()
          .addImage(
            imageFile.dataUrlBase64,
            imageFile.extension,
            X_MARGIN,
            Y_MARGIN,
            scaledWidth,
            scaledHeight
          );
      });
    });

    return documentPdfFile.output("blob");
  } catch (error) {
    logError(APP_V2_APP_EVENTS.CONSTRUCT_PDF_FROM_IMAGES_FAILURE, { error });
    throw error;
  }
};

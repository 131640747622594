import { Skeleton, Stack } from "@mui/material";
import { type ReactElement } from "react";

export function DocumentPageSkeleton(): ReactElement {
  return (
    <Stack
      spacing={6}
      sx={{
        paddingY: 2,
      }}
    >
      <Stack spacing={1} sx={{ alignItems: "center" }}>
        <Skeleton animation="wave" width="75%" />
        <Skeleton height="24px" variant="rectangular" animation="wave" width="100%" />
      </Stack>

      <Stack spacing={3}>
        {Array.from({ length: 6 }).map((_, index: number) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Stack key={index} spacing={2}>
              <Skeleton animation="wave" width="75%" />
              <Skeleton height="48px" variant="rectangular" animation="wave" />
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}

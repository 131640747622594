import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Card, CardActionArea, Stack } from "@mui/material";
import { type ReactElement } from "react";

export interface AccountRequirementCardProps {
  title: string;
  statusIcon?: ReactElement;
  actionIsRequired?: boolean;
  children?: ReactElement;
  onClick: () => void;
  disabled?: boolean;
}

export function AccountsRequirementCard(props: AccountRequirementCardProps): ReactElement {
  const { title, statusIcon = null, actionIsRequired = false, children, onClick, disabled } = props;

  return (
    <Card variant={actionIsRequired ? "outlined" : "elevation"}>
      <CardActionArea
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingY: 1.5,
          paddingX: 2,
          ...(!isDefined(children) && {
            alignItems: "center",
          }),
        }}
        disabled={disabled}
        onClick={onClick}
      >
        <Stack
          sx={{
            paddingRight: 1,
          }}
        >
          <Text variant="h3">{title}</Text>
          {children}
        </Stack>
        {statusIcon}
      </CardActionArea>
    </Card>
  );
}

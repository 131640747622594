import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import constate from "constate";
import { type Location } from "history";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  ACCOUNTS_BASE_PATH,
  ACCOUNTS_DOCUMENTS_PATH,
  DocumentRequirementType,
  useGetRequirementStatus,
} from "../../Documents";
import { ACCOUNT_ROUTE_V1 } from "../../Documents/constants";
import { getRequirementType } from "../../utils/getRequirementType";
import { PERSONAL_IDENTIFICATION_REQUIREMENT_ID } from "../constants";
import {
  useDocumentDetailsUrlParams,
  useDocumentPreview,
  useDocumentProperties,
  useLogDocumentDetailsEvent,
  useStripeVerificationFlow,
} from "../hooks";
import { getRequirementDetailsStatus } from "../utils";

interface LocationState {
  returnLocation?: Location;
}

function useDocumentDetails() {
  const { state: locationState } = useLocation<LocationState>();

  const {
    requirementId,
    requirementType: requirementTypeFromUrlParams,
    facilityId,
  } = useDocumentDetailsUrlParams();

  const worker = useDefinedWorker();

  const {
    hcpRequirementStatus,
    refetch: refetchRequirementStatus,
    isLoading: isLoadingRequirementStatus,
  } = useGetRequirementStatus({
    workerId: worker.userId,
  });

  const requirementType =
    requirementTypeFromUrlParams ??
    getRequirementType({
      requirementId,
      hcpRequirementStatus,
    });

  const { documentProperties, isLoading: isLoadingDocumentProperties } = useDocumentProperties({
    requirementId,
    requirementType,
    facilityId,
  });

  const {
    selectedRequirement,
    documentId,
    submittedDocumentSubtype,
    rejectedDocumentNotification,
  } = documentProperties;

  useLogDocumentDetailsEvent({
    selectedRequirement,
    documentRequirementType: requirementType,
  });

  const history = useHistory();

  const [personalIdSubtype, setPersonalIdSubtype] = useState<string>();
  const deleteDocumentModalState = useModalState();

  const isRequirementPersonalId = requirementId === PERSONAL_IDENTIFICATION_REQUIREMENT_ID;

  const {
    personalIdSubtypes,
    stripePersonalIdSubtypes,
    startStripeVerificationFlow,
    isStripeReady,
    isLoadingStripeVerificationSession,
    isStripeVerificationFlow,
  } = useStripeVerificationFlow({
    isRequirementPersonalId,
    personalIdSubtype,
  });

  if (isRequirementPersonalId && personalIdSubtypes.length > 0 && !isDefined(personalIdSubtype)) {
    setPersonalIdSubtype(personalIdSubtypes[0]);
  }

  const alertRequirementStatus = getRequirementDetailsStatus({
    selectedRequirement,
    documentRequirementType: requirementType,
  });

  const backLink =
    locationState?.returnLocation?.pathname ?? `${ACCOUNTS_BASE_PATH}/${ACCOUNTS_DOCUMENTS_PATH}`;
  const refreshQueryAndGoBackToDocumentsPage = async () => {
    if (isDefined(selectedRequirement)) {
      await refetchRequirementStatus();
    }

    history.replace(backLink, {
      ...(backLink === `${ACCOUNTS_BASE_PATH}/${ACCOUNTS_DOCUMENTS_PATH}`
        ? { returnUrl: ACCOUNT_ROUTE_V1 }
        : {}),
    });
  };

  const requirementTypeIsPending =
    isDefined(requirementType) && requirementType === DocumentRequirementType.PENDING;

  const { onClick: viewDocumentOnClick, isLoading: isDocumentPreviewLoading } =
    useDocumentPreview();

  // Hide view document button if uploaded doc is through stripe, and is
  // still pending since document may not be available to view yet
  const viewDocumentButtonIsVisible =
    !isRequirementPersonalId ||
    !isDefined(submittedDocumentSubtype) ||
    !stripePersonalIdSubtypes.includes(submittedDocumentSubtype) ||
    !requirementTypeIsPending;

  return {
    requirementId,
    requirementType,
    selectedRequirement,
    isLoadingDocumentProperties: isLoadingDocumentProperties || isLoadingRequirementStatus,
    documentId,
    rejectedDocumentNotification,
    isRequirementPersonalId,
    personalIdSubtype,
    setPersonalIdSubtype,
    personalIdSubtypes,
    startStripeVerificationFlow,
    isStripeReady,
    isLoadingStripeVerificationSession,
    isStripeVerificationFlow,
    backLink,
    deleteDocumentModalState,
    refreshQueryAndGoBackToDocumentsPage,
    alertRequirementStatus,
    requirementTypeIsPending,
    isDocumentPreviewLoading,
    viewDocumentOnClick,
    viewDocumentButtonIsVisible,
  };
}

export const [DocumentDetailsProvider, useDocumentDetailsContext] = constate(useDocumentDetails);

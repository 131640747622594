import { FileSelectionType, type UploadFileData } from "../types";
import {
  getFileSelectionType,
  getFileSelectionTypeFromSelectedFiles,
} from "./getFileSelectionType";

interface ValidateNewSelectedFileProps {
  newSelectedFile: UploadFileData;
  selectedFiles: UploadFileData[];
}

export function validateNewSelectedFile(props: ValidateNewSelectedFileProps) {
  const { newSelectedFile, selectedFiles } = props;

  const fileSectionType = getFileSelectionType(newSelectedFile);
  const fileSelectionTypeFromSelectedFiles = getFileSelectionTypeFromSelectedFiles(selectedFiles);

  if (
    selectedFiles.length > 0 &&
    (fileSectionType !== FileSelectionType.IMAGE ||
      fileSelectionTypeFromSelectedFiles !== FileSelectionType.IMAGE)
  ) {
    throw new Error("Invalid append file");
  }
}

import { isAcceptableImageUploadContentType } from "@src/appV2/lib/files";
import { isAcceptablePdfUploadContentType } from "@src/appV2/lib/files/utils";

import { FileSelectionType, type UploadFileData } from "../types";

export function getFileSelectionType(file: UploadFileData) {
  if (isAcceptablePdfUploadContentType(file.contentType)) {
    return FileSelectionType.PDF;
  }

  if (isAcceptableImageUploadContentType(file.contentType)) {
    return FileSelectionType.IMAGE;
  }

  throw new Error("Invalid file type selected for upload");
}

export function getFileSelectionTypeFromSelectedFiles(selectedFiles: UploadFileData[]) {
  if (selectedFiles.length === 0) {
    return undefined;
  }

  return getFileSelectionType(selectedFiles[0]);
}

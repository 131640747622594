import {
  blobToBase64,
  isAcceptableFileUploadContentType,
  isAcceptableImageUploadContentType,
} from "@src/appV2/lib/files";

import { type UploadFileData, type UploadImageFileData } from "../types";
import { constructPdfFromMultipleImages } from "./constructPdfFromMultipleImages";

/*
    We can either upload a single PDF file or a single Image file.
    We allow multiple image inputs, but we combine this into a single pdf before uploading
*/
export async function validateAndGetUploadFile(
  selectedFiles: UploadFileData[]
): Promise<UploadFileData> {
  if (selectedFiles.length === 1) {
    if (!isAcceptableFileUploadContentType(selectedFiles[0].contentType)) {
      throw new Error("Invalid file type selected for upload");
    }

    return selectedFiles[0];
  }

  if (
    selectedFiles.length > 1 &&
    selectedFiles.every((file) => isAcceptableImageUploadContentType(file.contentType))
  ) {
    const blob = await constructPdfFromMultipleImages(selectedFiles as UploadImageFileData[]);
    return {
      dataUrlBase64: await blobToBase64(blob),
      blob,
      extension: "pdf",
      contentType: "application/pdf",
    };
  }

  throw new Error("Invalid Selected Files");
}

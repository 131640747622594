import { camelCase, replace } from "lodash";

interface GetDocumentFileNameProps {
  requirementName: string;
  fileName?: string;
}

/*
  This function builds a document file name from the requirement name and an optional fileName
  The regular expression /[^\w.-]/ matches any character that is not any of the following:
    - a word character (letters, digits, and underscores), 
    - a dot
    - a hyphen.
  We replace all these matched characters with underscores
*/
export function getDocumentFileName(props: GetDocumentFileNameProps): string {
  const { requirementName, fileName } = props;
  const trimmedRequirementName = replace(requirementName, /[^\w.-]/, "_");
  return fileName
    ? camelCase(`${trimmedRequirementName} ${fileName}`)
    : camelCase(trimmedRequirementName);
}

import { Text, useModalState } from "@clipboard-health/ui-react";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, IconButton, Stack } from "@mui/material";
import { CommonDialog, ProgressBar } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type ReactElement } from "react";

import { MORE_INFO_MODAL_DESCRIPTION, MORE_INFO_MODAL_TITLE } from "./constants";

interface RequirementProgressBarProps {
  submittedRequirementCount: number;
  totalRequirementCount: number;
}

export function RequirementProgressBar(props: RequirementProgressBarProps): ReactElement {
  const { submittedRequirementCount, totalRequirementCount } = props;

  const percentOfSubmittedRequirement =
    (Math.min(submittedRequirementCount, totalRequirementCount) / totalRequirementCount) * 100;

  const moreInfoModalState = useModalState();

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Text variant="h3">
          <b>{submittedRequirementCount}</b>
        </Text>
        <Text variant="h3">&nbsp;of&nbsp;</Text>
        <Text variant="h3">
          <b>{totalRequirementCount}</b>
        </Text>
        <Text variant="h3">&nbsp;required documents&nbsp;</Text>
        <Box sx={{ display: "inline-flex", alignItems: "center", whiteSpace: "nowrap" }}>
          <Text variant="h3">submitted</Text>
          <IconButton
            size="small"
            sx={{ ml: 0.5 }}
            aria-label="More Info"
            onClick={() => {
              logEvent(APP_V2_USER_EVENTS.MISSING_DOCUMENTS_NOTIFY_SUPPORT_BUTTON_CLICKED, {
                submittedRequirementCount,
                totalRequirementCount,
              });
              moreInfoModalState.openModal();
            }}
          >
            <InfoIcon color="primary" />
          </IconButton>
        </Box>
      </Box>
      <ProgressBar value={percentOfSubmittedRequirement} />
      <CommonDialog
        modalState={moreInfoModalState}
        actions={
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              moreInfoModalState.closeModal();
            }}
          >
            Okay
          </Button>
        }
        title={MORE_INFO_MODAL_TITLE}
      >
        <Text variant="body1">{MORE_INFO_MODAL_DESCRIPTION}</Text>
      </CommonDialog>
    </Stack>
  );
}

import { isDefined } from "@clipboard-health/util-ts";

import { DocumentRequirementType, type HcpRequirement } from "../../Documents/types";
import { getIsExpiringSoon } from "../../utils";
import { RequirementStatus } from "../types";

interface GetRequirementDetailsStatus {
  documentRequirementType?: DocumentRequirementType;
  selectedRequirement?: HcpRequirement;
}

export function getRequirementDetailsStatus(
  params: GetRequirementDetailsStatus
): RequirementStatus | undefined {
  if (!isDefined(params.documentRequirementType) || !isDefined(params.selectedRequirement)) {
    return undefined;
  }

  const { selectedRequirement, documentRequirementType } = params;
  if (getIsExpiringSoon(selectedRequirement, documentRequirementType)) {
    return RequirementStatus.SOON_TO_EXPIRE;
  }

  if (documentRequirementType === DocumentRequirementType.COMPLETED) {
    return RequirementStatus.APPROVED;
  }

  if (documentRequirementType === DocumentRequirementType.PENDING) {
    return RequirementStatus.PENDING;
  }

  if (documentRequirementType === DocumentRequirementType.REJECTED) {
    return RequirementStatus.REJECTED;
  }

  if (documentRequirementType === DocumentRequirementType.EXPIRED) {
    return RequirementStatus.EXPIRED;
  }

  return undefined;
}

import { formatDate } from "@clipboard-health/date-time";
import { isDefined } from "@clipboard-health/util-ts";
import { parseISO } from "date-fns";

import { type HcpRequirement } from "../../Documents/types";
import { type RejectedDocumentNotification } from "../api/useGetRejectedDocumentNotification";
import { RequirementStatus } from "../types";

interface GetRequirementDetailsContentCopiesProps {
  requirementStatus: RequirementStatus;
  hcpRequirement: HcpRequirement;
  rejectedDocumentNotification?: RejectedDocumentNotification;
}

interface GetRequirementDetailsContentCopies {
  title?: string;
  description?: string;
  requirementStatus: RequirementStatus;
}

export function getRequirementDetailsContentCopies(
  props: GetRequirementDetailsContentCopiesProps
): GetRequirementDetailsContentCopies | undefined {
  const { hcpRequirement, requirementStatus, rejectedDocumentNotification } = props;

  if (requirementStatus === RequirementStatus.PENDING) {
    return {
      title: "Verification Pending",
      requirementStatus,
    };
  }

  if (requirementStatus === RequirementStatus.EXPIRED) {
    return {
      title: "Expired",
      description: "Please upload a replacement.",
      requirementStatus,
    };
  }

  if (requirementStatus === RequirementStatus.REJECTED) {
    return {
      title: "Rejected",
      description: rejectedDocumentNotification?.note ?? undefined,
      requirementStatus,
    };
  }

  const { expiry: expirationDateOfRequirement } = hcpRequirement;

  const expirationAlertText = isDefined(expirationDateOfRequirement)
    ? `Expires ${formatDate(parseISO(expirationDateOfRequirement))}`
    : undefined;

  if (requirementStatus === RequirementStatus.SOON_TO_EXPIRE) {
    return {
      title: expirationAlertText,
      description: "Please upload a replacement.",
      requirementStatus,
    };
  }

  if (requirementStatus === RequirementStatus.APPROVED) {
    return {
      title: "Approved",
      description: expirationAlertText,
      requirementStatus,
    };
  }

  return undefined;
}
